import request from './axios'

export function getSystemLog(params) { //
    return request({
        url: '/api/systemLog/getListPage',
        method: 'GET',
        params,
    })
}
export function getLogDetail(params) { //
    return request({
        url: '/api/systemLog/getLogDetail',
        method: 'GET',
        params,
    })
}
