<template>
    <div>

        <div class="common-header">
            <div class="left-wrap" @click="$router.go(-1)">
                <i class="el-icon-arrow-left visit-back"></i>
                <span class="visit-title">操作日志详情</span>
            </div>
        </div>
        <div class="log-detail-box research-data">


            <span class="research-data_title">日志类型:</span>
            <span class="research-data_detail">{{log.logType|convertDict('logType',dict,log.logType)}}</span>
            <br>
            <span class="research-data_title">操作模块:</span>
            <span class="research-data_detail">{{log.model}}</span>

            <span class="research-data_title">操作方式:</span>
            <span class="research-data_detail">{{log.operateType|convertDict('operateType',dict,log.operateType)}}</span>

            <span class="research-data_title">操作描述:</span>
            <span class="research-data_detail">{{log.operateDesc}}</span>

            <span class="research-data_title">请求参数:</span>
            <span class="research-data_detail">{{log.resquestParam}}</span>

            <span class="research-data_title">后端调用类:</span>
            <span class="research-data_detail">{{log.actionClass}}</span>

            <span class="research-data_title">后端调用方法:</span>
            <span class="research-data_detail">{{log.actionMethod}}</span>

            <span class="research-data_title">研究标题:</span>
            <span class="research-data_detail">{{log.researchName}}</span>

            <span class="research-data_title">研究code:</span>
            <span class="research-data_detail">{{log.researchCode}}</span>

            <span class="research-data_title">操作用户:</span>
            <span class="research-data_detail">{{log.userName}}</span>

            <span class="research-data_title">操作环境:</span>
            <span class="research-data_detail">{{log.env|convertDict('env',dict,log.env)}}</span>

            <span class="research-data_title">操作版本:</span>
            <span class="research-data_detail">{{log.researchVersionNum}}</span>

            <span class="research-data_title">版本code:</span>
            <span class="research-data_detail">{{log.researchVersionCode}}</span>

            <span class="research-data_title">异常信息:</span>
            <span class="research-data_detail">{{log.errMessage}}</span>

            <span class="research-data_title">IP地址:</span>
            <span class="research-data_detail">{{log.ip}}</span>

            <span class="research-data_title">URI:</span>
            <span class="research-data_detail">{{log.uri}}</span>

            <span class="research-data_title">操作日期:</span>
            <span class="research-data_detail">{{log.operateDate}}</span>


        </div>
    </div>
</template>

<script>
    import {getLogDetail} from "@/api/system";
    import {SelectorDefine} from '../../utils/SelectorDefine'

    export default {
        filters: {
            convertDict(prop = '', str = '', dict = {}) {
                let dictList = dict[str];
                if (dictList && dictList.length)
                    for (let i = 0; i < dictList.length; i++)
                        if (dictList[i].value == prop)
                            return dictList[i].label;
                return prop;
            },
        },
        components: {},
        data() {
            return {
                dict: {},
                log: {},
            }
        },
        methods: {
            getLogDetail(param) {
                if (param && param.pkId)
                    getLogDetail(param).then(r => {
                        console.log('获取日志对象', r);
                        this.log = r.data;
                    })
            }
        },
        mounted() {
            this.dict = SelectorDefine().SystemLog;
            this.getLogDetail({pkId: this.$route.query.logId})
        }

    }
</script>

<style scoped lang="scss">
    .research-data {
        padding-left: 42px;
        font-size: 16px;
        color: #212121;
        .research-data_title {
            display: inline-block;
            width: 100px;
            text-align: right;
            margin-right: 10px;
            font-size: 15px;
        }
        .research-data_detail {
            display: inline-block;
            width: calc(100% - 120px);
            vertical-align: top;
            margin-bottom: 10px;
            color: #777;
            font-size: 15px;
        }
    }

    .log-detail-box {

    }
</style>