let FormEdit = {
    commonDataSource: [
        {label: '人工录入', value: 0},
        {label: '默认值', value: 2},

    ],
    calculateDataSource: [
        {label: '人工录入', value: 0},
        {label: '逻辑计算', value: 1},
        {label: '默认值', value: 2},
    ],
    operatorList: [
        {label: '等于', value: '=='},
        {label: '不等于', value: '!='},
        {label: '小于', value: '<'},
        {label: '小于等于', value: '<='},
        {label: '大于', value: '>'},
        {label: '大于等于', value: '>='},
        {label: '介于', value: '[]'},
        {label: '包含', value: '{}',},
        {label: '不包含', value: '{/}'},
    ],
    labelLevel: [
        {lable: '18px', value: '1', text: '一级标题'},
        {lable: '16px', value: '2', text: '二级标题'},
        {lable: '14px', value: '3', text: '三级标题'},
    ],
    yesNoStr: [
        {label: '1', value: '1', text: '是'},
        {label: '0', value: '0', text: '否'},
    ],
    yesNoNum: [
        {label: '1', value: 1, text: '是'},
        {label: '0', value: 0, text: '否'},
    ],
    calculateDateUnit: [
        {label: '年', value: 1},
        {label: '月', value: 2},
        {label: '日', value: 3},
        {label: '小时', value: 4},
        {label: '分钟', value: 5},
    ],
    calculateType: [
        {label: '1', value: 1, text: '数值'},
        {label: '2', value: 2, text: '时间间隔'},
    ]
};
let Visit = {
    category: [
        {label: '自定义', value: '0'},
        // {label: '不良事件', value: 1},
        // {label: '合并用药', value: 2},
        // {label: '问卷量表', value: 3},
        // {label: '医疗设备', value: 4},
        // {label: '实验室检查', value: 5},
        // {label: '其他', value: 6}
    ],
    selectionType: [
        {label: '可重复', value: "Multiple"},
        {label: '不可重复', value: "Single"},
    ],
    isEpro: [
        {label: '1', value: '1', text: '是'},
        {label: '0', value: '0', text: '否'},
    ],
}

let SystemLog = {
    operateType: [
        {label: '增加', value: '1'},
        {label: '删除', value: '2'},
        {label: '查找', value: '3'},
        {label: '修改', value: '4'},
    ],
    logType: [
        {label: '操作日志', value: '1'},
        {label: '异常日志', value: '2'},
    ],
    env: [
        {label: '测试环境', value: '1'},
        {label: '生产环境', value: '2'},
    ],
}

export function SelectorDefine() {
    return {
        FormEdit, Visit, SystemLog
    };
};